import { useState } from 'react';
import firebase from '../firebase';

const useUploadVideo = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [videos, setVideos] = useState([]);

  // Function to upload a video
  const uploadVideo = async (videoFile, title, description, documentName = 'videos') => {
    setUploading(true);
    setError(null);

    try {
      // Upload the video file to Firebase Storage
      const storageRef = firebase.storage().ref();
      const videoRef = storageRef.child(`videos/${videoFile.name}`);
      await videoRef.put(videoFile);

      // Get the video URL
      const videoUrl = await videoRef.getDownloadURL();

      // Video metadata
      const videoData = {
        title,
        description,
        videoUrl,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
      };

      // Save video metadata to Realtime Database
      const databaseRef = firebase.database().ref(documentName);
      const newVideoRef = databaseRef.push();
      await newVideoRef.set(videoData);

      setUploading(false);
      return { success: true, videoId: newVideoRef.key };
    } catch (err) {
      setUploading(false);
      setError(err.message);
      return { success: false, error: err.message };
    }
  };

  // Function to retrieve videos
  const fetchVideos = async (documentName = 'videos') => {
    try {
      const databaseRef = firebase.database().ref(documentName);
      const snapshot = await databaseRef.once('value');

      if (snapshot.exists()) {
        const videoList = [];
        snapshot.forEach((childSnapshot) => {
          videoList.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        setVideos(videoList);
      } else {
        setVideos([]);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return { uploadVideo, fetchVideos, videos, uploading, error };
};

export default useUploadVideo;
