import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import useUploadVideo from "../../hooks/useUploadVideo"; // Import the custom hook

const VideoUploadModal = ({ open, onClose }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { uploadVideo, uploading, error } = useUploadVideo(); // Use the hook for video uploads

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setVideoFile(file);
    } else {
      alert("Please select a valid video file.");
    }
  };

  const handleUpload = async () => {
    if (!videoFile || !title) {
      alert("Video file and title are required.");
      return;
    }

    const result = await uploadVideo(videoFile, title, description);
    if (result.success) {
      alert("Video uploaded successfully!");
      onClose(); // Close the modal after successful upload
    } else {
      alert(`Upload failed: ${result.error}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#181818", // Dark background
          color: "#FFFFFF", // White text
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold" }}
      >
        Upload Video
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          {/* Error Alert */}
          {error && (
            <Alert
              severity="error"
              sx={{
                backgroundColor: "#4a0000",
                color: "#FFFFFF",
              }}
            >
              {error}
            </Alert>
          )}
          <TextField
            label="Title"
            variant="filled"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            InputProps={{
              style: { backgroundColor: "#202020", color: "#FFFFFF" },
            }}
            InputLabelProps={{
              style: { color: "#AAAAAA" },
            }}
          />
          <TextField
            label="Description"
            variant="filled"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            InputProps={{
              style: { backgroundColor: "#202020", color: "#FFFFFF" },
            }}
            InputLabelProps={{
              style: { color: "#AAAAAA" },
            }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{
              backgroundColor: "#CC0000",
              "&:hover": {
                backgroundColor: "#FF0000",
              },
            }}
          >
            Choose File
            <input
              type="file"
              hidden
              accept="video/mp4,video/x-m4v,video/*"
              onChange={handleFileChange}
            />
          </Button>
          {videoFile && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="body2">
                <strong>Selected File:</strong> {videoFile.name} (
                {(videoFile.size / 1024 / 1024).toFixed(2)} MB)
              </Typography>
              <Typography variant="body2">
                <strong>Type:</strong> {videoFile.type}
              </Typography>
              <Box
                sx={{
                  marginTop: 2,
                  width: "100%",
                  height: "200px",
                  position: "relative",
                  backgroundColor: "#202020",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <video
                  controls
                  src={URL.createObjectURL(videoFile)}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                >
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "#303030",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#505050",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          disabled={!videoFile || !title || uploading}
          sx={{
            backgroundColor: "#CC0000",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FF0000",
            },
          }}
        >
          {uploading ? <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VideoUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VideoUploadModal;
